<template>
  <CModal :show="show" :closeOnBackdrop="false" :centered="true">
    <template #header>
      <h5 class="modal-title">{{ title }}</h5>
    </template>

    <div v-if="invoice !== null">
      <CAlert v-if="error !== null" color="danger" class="mb-3">
        {{ error }}
      </CAlert>

      <div class="ui-type-subheading text-uppercase text-gray-600">
        {{ $t("role.finance.invoicePaidModal.recipient") }}
      </div>
      <h4>{{ invoiceDestinatary() }}</h4>
      <br />

      <div class="ui-type-subheading text-uppercase text-gray-600">
        {{ $t("role.finance.invoicePaidModal.amount") }}
      </div>
      <h4>{{ invoiceAmount() }}</h4>
      <br />

      <form action="" @submit.prevent="onSubmit" ref="form">
        <CRow>
          <CCol md="3">
            <UiDatePicker
              label="Dal"
              :date.sync="form.data_payed"
              :isValid="isValid('data_payed')"
              :invalidFeedback="getFieldError('data_payed')"
              :clearButton="false"
              :readonly="true"
            >
              <template #label>
                <strong>{{
                  $t("role.finance.invoicePaidModal.paymentDate")
                }}</strong>
                <em class="text-danger">*</em>
              </template>
            </UiDatePicker>
          </CCol>
        </CRow>
      </form>
    </div>

    <template #footer>
      <CButton color="primary" variant="outline" @click.prevent="close">{{
        $t("role.finance.invoiceEditModal.cancelBtn")
      }}</CButton>
      <vue-ladda
        :loading="loading"
        data-style="zoom-in"
        button-class="btn btn-primary px-4"
        @click.prevent="onSubmit"
        >{{ $t("role.finance.invoicePaidModal.saveBtn") }}</vue-ladda
      >
    </template>
  </CModal>
</template>

<script>
import get from "lodash/get";
import EventBus from "../../../../helpers/EventBus";
import { formatDate, formatPrice } from "../../../../helpers/common";
import UiDatePicker from "../../../../common/form/UiDatePicker";

export default {
  name: "InvoicesPaidModal",

  components: {
    UiDatePicker,
  },

  data() {
    return {
      loading: false,
      error: null,
      form: {
        data_payed: new Date(),
      },
    };
  },

  watch: {
    show(value) {
      if (value) {
        this.reset();
      }
    },
  },

  computed: {
    title() {
      return this.$t("role.finance.invoicePaidModal.title");
    },

    show() {
      return this.$store.state.invoices.paid.show;
    },

    invoice() {
      return this.$store.state.invoices.paid.invoice;
    },
  },

  methods: {
    reset() {
      this.loading = false;
      this.error = null;
      this.form = {
        data_payed: new Date(),
      };
    },

    close() {
      this.$store.commit("invoices/closePaidModal");
    },

    invoiceDestinatary() {
      return this.invoice.i_from.name;
    },

    invoiceAmount() {
      const currencySymbol = this.$store.getters["connections/currencySymbol"];
      return formatPrice(this.invoice.price, currencySymbol).format();
    },

    onSubmit() {
      const familyId = this.$store.state.role.family_id;
      const invoiceId = this.invoice.id;

      this.error = null;
      this.loading = true;
      this.$store
        .dispatch("invoices/paidInvoice", { familyId, invoiceId, ...this.form })
        .then((response) => {
          this.close();
          EventBus.$emit("invoices:refresh");
        })
        .catch((error) => {
          this.error = error.response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    isValid(key) {
      const s =
        get(this.error, key, undefined) !== undefined ? false : undefined;
      return s;
    },

    haveFieldError(field) {
      return get(this.error, `errors.fields.${field}`, null) !== null;
    },

    getFieldError(field) {
      return get(this.error, `errors.fields.${field}`, null);
    },

    now() {
      return formatDate(new Date(), "dd-MM-yyyy");
    },
  },
};
</script>
